import React from 'react';
import useViewport from '../../hooks/useViewport';

import { MobileMenu, NavBar } from '../../components';

const TopNavbar = () => {
  const { width } = useViewport();
  const breakpoint = 768;

  return width <= breakpoint ? <MobileMenu />: <NavBar />;
};

export default TopNavbar;