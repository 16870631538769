import React from 'react';
import { motion, useCycle } from 'framer-motion';
import { MenuToggle } from './MenuToggle';
import { Navigation } from './Navigation';
import Logo from '../logo/Logo';

import styles from './mobilemenu.module.css';


const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2
    }
  }),
  closed: {
    clipPath: "circle(30px at 40px 40px)",
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40
    }
  }
};

const MobileMenu = () => {
  const [isOpen, toggleOpen] = useCycle(false, true);

  return (
    <motion.nav
      className={styles['mobile-nav']}
      initial={false}
      animate={isOpen ? "open" : "closed"}
    >
      <Logo />
      <motion.div className={styles['background']} variants={sidebar} />
      <Navigation styles={styles} />
      <MenuToggle styles={styles} toggle={() => toggleOpen()} />
    </motion.nav>
  );
};

export default MobileMenu;