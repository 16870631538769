import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ViewportProvider } from './viewport-context/ViewportProvider';

import App from './App';
import './index.css';

const AppUI = () => {
  return (
    <BrowserRouter>
      <ViewportProvider>
        <App />
      </ViewportProvider>
    </BrowserRouter>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<AppUI />);