import React from 'react';
import CustomLink from '../../components/customlinks/CustomLink';

import styles from './footer.module.css';

const Footer = () => {
  return (
    <footer className={styles['footer']}>
      <div className={styles['footer_copyright']}>
        <span>© Dmitriy Parhomenko 2023</span>
      </div>
      <div className={styles['footer_links']}>
        <CustomLink
          params={{
            toHref: '/#about',
            content: 'about'
          }}
        />
        <CustomLink
          params={{
            toHref: '/#portfolio',
            content: 'portfolio'
          }}
        />
      </div>
    </footer>
  )
}

export default Footer;
