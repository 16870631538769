import React from 'react';
import { motion } from 'framer-motion';
import CustomLink from '../customlinks/CustomLink.js';
import { SvgLinkedin, SvgMail } from '../svg/SvgIcons.js';

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
};

const RenderLinks = ({ index }) => {
  if(index === 0) {
    return ( <CustomLink
      params={{
        toHref: '/#about',
        content: 'about',
        isClass: 'mobile-link'
      }}
    /> )
  } else if(index === 1) {
    return ( <CustomLink
      params={{
        toHref: '/#portfolio',
        content: 'portfolio',
        isClass: 'mobile-link'
      }}
    /> )
  } else if(index === 2) {
    return ( <CustomLink 
      params={{
        toHref: 'https://www.linkedin.com/in/dmitriy-parhomenko-5101b9186/',
        isTarget: '_blank',
        isRel: 'noreferrer',
        isClass: 'icon-linkedin',
        content: <SvgLinkedin />
      }}
    /> )
  } else if(index === 3) {
    return ( <CustomLink 
      params={{
        toHref: 'mailto:dmitriyparhomenko140@gmail.com',
        isRel: 'noreferrer',
        isClass: 'icon-mail',
        content: <SvgMail />
      }}
    />)
  }
}

export const MenuItem = ({ i, styles }) => {
  return (
    <motion.li
      className={styles['mobile_link']}
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
      <div className={styles['text-placeholder']}>
        <RenderLinks index={i} />
      </div>

    </motion.li>
  );
};