const dataProjects = [
  {
    id: 1,
    project_label: 'artipoppe',
    url: 'artipoppe',
    project_ids: ['all', 'work-related'],
    title: 'Artipoppe',
    logo: "/images/projects/artipoppe/artipoppe-logo.svg",
    images: ['/images/projects/artipoppe/cart_page.jpg', '/images/projects/artipoppe/checkout_page.jpg'],
    content: "<p>As a front-end developer, I collaborated with a skilled backend developer to improve the user experience of the cart and checkout pages. The design was provided by the customer, and I utilized my expertise in front-end development to transform the design into a functional and visually appealing interface. I implemented Ajax on the checkout page to improve the speed and efficiency of the process, and seamlessly integrated the backend developer's API to create a highly functional cart and checkout system. The result was an enhanced user experience that increased engagement and conversion rates, leading to a significant boost in sales for the business.</p>",
    skills: ['HTML5','CSS3', 'LESS', 'JavaScript', 'jQuery', 'Ajax', 'Twig', 'Figma', 'GitLab'],
    project_url_website: 'https://shop.artipoppe.com/'
  },
  {
    id: 2,
    project_label: 'triona',
    url: 'triona',
    project_ids: ['all', 'work-related'],
    title: 'Triona',
    logo: "/images/projects/triona/triona-logo.svg",
    images: ['/images/projects/triona/figma.jpg', '/images/projects/triona/home.gif'],
    content: "<p>One of my major contributions to the project was creating a custom theme for the website using my expertise in HTML, CSS, and JavaScript. I used Figma to design the layout and carefully implemented the design to meet the project requirements. To integrate the site with Shopware 6, I utilized the TWIG templating system, and I also used SASS to develop the site's styles, resulting in a cohesive and visually appealing final product.</p>",
    skills: ['HTML5','CSS3', 'SASS', 'JavaScript', 'jQuery', 'Twig', 'Bootstrap', 'Figma', 'GitLab'],
    project_url_website: 'https://trionaclothing.com/'
  },
  {
    id: 3,
    project_label: 'dev-raiks-piskel',
    url: 'dev-raiks-piskel',
    project_ids: ['all', 'personal-projects'],
    title: 'Dev Raiks Piskel',
    logo: "/images/projects/dev-raiks-piskel/DevRaiksPiskel.svg",
    images: ['/images/projects/dev-raiks-piskel/home.gif', '/images/projects/dev-raiks-piskel/edit.gif'],
    content: "<p>A web application has been developed that closely mimics the functionality of the <a href='https://www.piskelapp.com/' target='_blank'>https://www.piskelapp.com/</a> service. The application provides users with the ability to create animations by drawing on a canvas using a range of available tools. The resulting animation can be downloaded in either PNG or SVG format, allowing users to easily incorporate their creations into other projects. The application was developed using native JavaScript, ensuring that it is both lightweight and fast-loading. Additionally, the user interface was designed to be intuitive and user-friendly, allowing users of all skill levels to quickly get started and create impressive animations.</p>",
    skills: ['HTML5','CSS3', 'JavaScript', 'Canvas', 'GitHub'],
    project_url_website: 'https://dmitriyparhomenko.github.io/start-page-piskel-clone/'
  },
  {
    id: 4,
    project_label: 'chicwish',
    url: 'chicwish',
    project_ids: ['all', 'work-related'],
    title: 'Chicwish',
    logo: "/images/projects/chicwish/logo_header.svg",
    images: ['/images/projects/chicwish/home-products.gif', '/images/projects/chicwish/product.gif'],
    content: "<p>As part of a website revamp project, I was responsible for migrating the Front End part of the site from CMS Magento 1 to Magento 2.</p> <p>In addition to the migration, I was also tasked with creating an adaptive slider on product pages to enhance the user experience and provide an engaging way for customers to view products. This required a deep understanding of JavaScript and CSS to ensure the slider was responsive across all devices.</p> <p>Finally, I was also responsible for the development of the front-end part for a custom module called 'Shop the Look' and a block of reviews. This required a thorough understanding of the underlying data structures and how they interacted with the back-end systems, as well as a strong knowledge of JavaScript, CSS, and HTML to create visually appealing and user-friendly interfaces.</p> <p>Overall, my work on this project involved a wide range of front-end development tasks and required a deep understanding of JavaScript, CSS, and HTML, as well as a keen attention to detail and a dedication to providing the best possible user experience for the site's customers.</p>",
    skills: ['HTML5','CSS3', 'LESS', 'JavaScript', 'jQuery', 'Magento 2', 'XML', 'KnockoutJS', 'A/B Testing', 'Figma', 'GitLab'],
    project_url_website: 'https://www.chicwish.com/'
  },
  {
    id: 5,
    project_label: 'marinenanoshop',
    url: 'marinenanoshop',
    project_ids: ['all', 'work-related'],
    title: 'Marine Nano Shop',
    logo: "/images/projects/marinenanoshop/logo.svg",
    images: ['/images/projects/marinenanoshop/home.jpg', '/images/projects/marinenanoshop/figma.jpg'],
    content: "<p>As part of my job, I created attractive promo web pages that matched the given design. I used different tools like Javascript, SASS, and Shopify Theme Kit to make the pages look good and work well.</p> <p>To achieve this, I made changes to Shopify liquid templates and added new features using the Section schema. My work helped improve the overall experience for users.</p>",
    skills: ['HTML5','CSS3', 'SASS', 'JavaScript', 'Shopify', 'Figma'],
    project_url_website: 'https://marinenanoshop.com/'
  },
  {
    id: 6,
    project_label: 'dev-raiks-portfolio',
    url: 'dev-raiks-portfolio',
    project_ids: ['all', 'personal-projects'],
    title: 'My Portfolio',
    logo: "/images/projects/dev-raiks-portfolio/logo.svg",
    images: ['/images/projects/dev-raiks-portfolio/figma.jpg', '/images/projects/dev-raiks-portfolio/home.gif', '/images/projects/dev-raiks-portfolio/project.gif'],
    content: "<p>I developed my own portfolio website, starting with designing the user interface and experience using Figma. To bring my design to life, I utilized ReactJS, a powerful Javascript library, to create dynamic and responsive web pages.</p><p>In particular, I leveraged routers and custom hooks to ensure seamless navigation and functionality across the site. I also employed React context to manage and share state data across components, enabling efficient and effective communication between different parts of the site.</p><p>To further enhance the user experience, I utilized Motion and JSAP technology to create engaging and visually appealing site animations. These technologies allowed me to bring my site to life, capturing the attention of visitors and highlighting my skills and experience in a compelling and memorable way.</p>",
    skills: ['HTML5','CSS3', 'ReactJS', 'JavaScript', 'Motion', 'GSAP', 'UI/UX', 'Figma'],
    project_url_website: ''
  },
  {
    id: 7,
    project_label: 'dev-raiks-email-templates',
    url: 'dev-raiks-email-templates',
    project_ids: ['all', 'email-templates'],
    title: 'Biocontainers Email',
    logo: "/images/projects/meissner/logo.svg",
    images: ['/images/projects/meissner/desktop.gif'],
    content: "<p>The email template has been designed with a modern look and is optimized to be flexible and adaptable to various screen sizes and devices. This means that it is fully responsive and can adjust its layout to ensure that the content is easy to read and access, regardless of the screen size or device being used to view it.</p>",
    skills: ['HTML5','CSS3'],
    project_url_website: 'https://devraiks.com/test-email-meissner/email/index.html'
  }
]

export default dataProjects;