import React, { useRef, useEffect } from 'react';
import imageMe from '../../assets/me.png';
import { ShortInfoLines, HeaderLineOne, HeaderLineTwo  } from '../../components/svg/SvgIcons.js';
import { motion, AnimatePresence } from 'framer-motion';
import gsap from 'gsap-trial';
import ScrollTrigger from 'gsap-trial/ScrollTrigger';
import useViewport from '../../hooks/useViewport';

import styles from './Header.module.css';

const Header = () => {
  const { width } = useViewport();
  const sectionRef = useRef(null);
  
  if (width >= 768) {
    gsap.registerPlugin(ScrollTrigger)
  }
  
  useEffect(() => {
    const elem = sectionRef.current;

    if (width >= 768) {
      gsap.fromTo(elem, { opacity: 1 }, {
        opacity: 0,
        scrollTrigger: {
          trigger: elem,
          start: 'start',
          end: 'bottom',
          scrub: true
        }
      })
    }
  }, [width])

  return (
    <AnimatePresence>
      <motion.section 
        ref={sectionRef}
        animate={{ opacity: 1, y: 0}}
        transition={{ delay: 0.2, y: { duration: 0.5 } }}
        initial={{ opacity: 0, y: 100 }}
        className={styles['header']}>
        <HeaderLineOne idTag="header_line-one" />
        <div className={styles['header_info']}>
          <ShortInfoLines />
          <h1>
            <span>&lt;h1&gt;</span> Dmitriy <span>&lt;/h1&gt;</span><br/>
            <span className={styles['header_info_job']}>Front End Developer</span>
          </h1>
        </div>
        <div className={styles['header_image']}>
          <img src={imageMe} alt="Dmitriy" title="Dmitriy" />
        </div>
        <HeaderLineTwo idTag="header_line-two" />
      </motion.section >
    </AnimatePresence>
  )
}

export default Header;
