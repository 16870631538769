import React from 'react';
import { TopNavbar, Footer } from '../../containers';

import styles from './errorpage.module.css';

const ErrorPage = () => {
  return (
    <main className={styles['error-main']}>
      <TopNavbar />
      <section className={styles['error_page']}>
        <h1>Page not found</h1>
      </section>
      <Footer />
    </main>
  )
}

export default ErrorPage;
