import React, { useState, useEffect } from 'react';
import dataProjects from '../../data-projects/dataProjects';
import { motion, AnimatePresence } from 'framer-motion';

import Filter from '../../components/filter/Filter';
import PortfolioItem from '../../components/portfolioitem/PortfolioItem';

import { PortfolioLine } from '../../components/svg/SvgIcons';
import styles from './portfolio.module.css';

const Portfolio = () => {
  const [projects, setProjects] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [activeProject, setActiveProject] = useState('all');

  useEffect(() => {
    initDataProjects();
  }, []);

  const initDataProjects = () => {
    setProjects(dataProjects);
    setFiltered(dataProjects);
  };

  return (
    <motion.section
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      id='portfolio' 
      className={styles['portfolio']}
    >
      <h2 className={styles['portfolio_title']}>portfolio.</h2>
      <div className={styles['portfolio_filter']}>
        <Filter
          projects={projects}
          setFiltered={setFiltered}
          activeProject={activeProject}
          setActiveProject={setActiveProject}
        />
      </div>
      <motion.div
        layout
        className={styles['portfolio_items']}
      >
        <AnimatePresence>
          {
            filtered.map(project => {
              return (
                <PortfolioItem
                  key={project.id}
                  projectLabel={project.project_label}
                  urlItem={project.url}
                  logoLable={project.title}
                  logoImg={project.logo}
                  projectInfo={project.content}
                />
              )
            })
          }
        </AnimatePresence>
      </motion.div>
      <PortfolioLine />
    </motion.section>
  )
}

export default Portfolio;
