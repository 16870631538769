import React, { useEffect } from 'react';
import { BtnRadiusOne, BtnRadiusTwo, BtnBottomLine } from '../svg/SvgIcons';

import styles from './filterbutton.module.css';

const FilterButton = ({ filterId, content, projects, setFiltered, activeProject, setActiveProject }) => {

  useEffect(() => {
    if(activeProject === 'all') {
      setFiltered(projects);
      return;
    }

    const filtered = projects.filter(project => project.project_ids.includes(activeProject));

    setFiltered(filtered);
  }, [projects, activeProject, setFiltered])

  const handleFilterBtn = event => {
    event.preventDefault();
    setActiveProject(filterId);
  }

  return (
    <a 
      className={`${styles['btn']} ${activeProject === filterId && styles['btn-active']}`} 
      href="#filter"
      onClick={handleFilterBtn}>
      <BtnRadiusOne />
      {content}
      <BtnRadiusTwo />
      <BtnBottomLine />
    </a>
  )
}

export default FilterButton;
