import React, { useEffect, useCallback, useMemo } from 'react';
import TagCloud from 'TagCloud';
import useViewport from '../../hooks/useViewport';

import './textshpere.css';

const TextShpere = () => {
  const skills = useMemo(() => [
    'WordPress',
    'Photoshop',
    'Figma',
    'Bootstrap',
    'knockoutJS',
    'Shopify',
    'ReactJS',
    'Magento 2',
    'CSS3',
    'HTML5',
    'JavaScript',
    'jQuery',
    'SASS/LESS',
    'GIT'
  ], []);
  let otherTcs = useMemo(() => [], []);

  const { width } = useViewport();

  const tagCloud = () => {
    if(width >= 768) {
      return <span className='tagcloud'></span>;
    } else {
      return (
        <span className='tagcloud'>
          <ul>
            {
              skills.map((skill, index) => <li key={index} >{skill}</li>)
            }
          </ul>
        </span>
      )
    }
  };

  const toCreate = useCallback(() => {
    const container = '.tagcloud';

    const options = {
      radius: width <= 768 ? 150 : width >= 769 && width <= 1050 ? 200 : 250,
      maxSpeed: 'slow'
    };

    if (otherTcs.length >= 3) return;

    otherTcs.push(TagCloud(container, skills, options));
  }, [width, otherTcs, skills]);

  const toDestroy = useCallback(() => {
    let last = otherTcs[otherTcs.length - 1];
    if (!last) return;
    last.destroy();
    otherTcs.pop();
  }, [otherTcs])

  useEffect(() => {
    if(width >= 768) {
      toCreate();
      return () => toDestroy();
    }
  }, [width, toCreate, toDestroy]);

  return (
    <div className='text-shpere'>
      {tagCloud()}
    </div>
  )
}

export default TextShpere;
