import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ZoomIcon } from '../svg/SvgIcons';
import parse from 'html-react-parser';

import styles from './portfolioitem.module.css';

const cutText = (text = '', count = 0) => {
  let shortText = text.slice(0, count);
  return `${shortText}...`;
}

const InfoItem = ({ urlItem, projectLabel, logoLable, logoImg, projectInfo }) => {
  return (
    <Fragment>
      <Link to={`/project/${urlItem}`} className={styles['more-info']} title="Show project">
        <ZoomIcon />
      </Link>
      <div className={styles['portfolio_items-item-wrapper']} data-label={projectLabel} >
        <img
          className={styles['portfolio_items-item-logo']}
          src={logoImg}
          alt={logoLable}
        />
      </div>
      <div className={styles['portfolio_items-item-shortdescr']} data-label={projectLabel} >
        {parse(cutText(projectInfo, 176))}
      </div>
    </Fragment>
  )
}

const PortfolioItem = ({ urlItem, projectLabel, logoLable, logoImg, projectInfo }) => {
  return (
    <motion.div
      layout
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      className={styles['portfolio_items-item']}
    >
      <InfoItem 
        urlItem={urlItem}
        projectLabel={projectLabel}
        logoLable={logoLable} 
        logoImg={logoImg} 
        projectInfo={projectInfo}
      />
    </motion.div>
  )
}

export default PortfolioItem;
