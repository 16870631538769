import React, { Fragment, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { motion, useIsPresent } from 'framer-motion';
import { TopNavbar, Footer } from '../../containers';
import ErrorPage from '../errorpage/ErrorPage';
import parse from 'html-react-parser';

import dataProjects from '../../data-projects/dataProjects';

import styles from './portfolioinfo.module.css';

const ProjectImages = ({ image, title }) => {
  return (
    <li>
      <img src={image} alt={title} />
    </li>
  )
}

const ProjectSkills = ({ skill }) => {
  return (
    <li>{skill}</li>
  )
}

const PortfolioInfo = () => {
  const { projectUrl } = useParams();
  const isPresent = useIsPresent();
  let project = dataProjects.find(project => project.url === projectUrl);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);

  const initPortfolioInfo = () => {
    const { id, project_label, title, images, content, skills, project_url_website } = project;

    const showVisitLink = () => {
      if(!project_url_website) {
        return null;
      }

      return (
        <a 
          className={styles['project_visit']} 
          href={project_url_website} 
          rel="noreferrer" 
          target="_blank">
            Visit: {title}
        </a>
      )
    }

    return (
      <Fragment>
        <TopNavbar />
        <section data-label={project_label} className={styles['single-project']} id='single-project'>
          <h1 className={styles['main-title']} >{title}</h1>
          <ul className={styles['project-images']}>
            {
              images.map((image, index) => {
                return (
                  <ProjectImages
                    key={`${id}-${index}`}
                    image={image}
                    title={title}
                  />
                )
              })
            }
          </ul>
          <article className={styles['project_content']}>
            <div className={styles['project_content-descr']}>
              {parse(content)}
            </div>
            <div className={styles['project_content-skills']}>
              <ul className={styles['project_skills']}>
                {
                  skills.map((skill, index) => {
                    return (
                      <ProjectSkills
                        key={`${id}-${index}`}
                        skill={skill}
                      />
                    )
                  })
                }
              </ul>
            </div>
          </article>
          <div className={styles['project_nav']}>
            {showVisitLink()}
            <Link to='/#portfolio'>Back to projects</Link>
          </div>
          <motion.div
            initial={{ scaleX: 1 }}
            animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circOut" } }}
            exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
            style={{ originX: isPresent ? 0 : 1 }}
            className="privacy-screen"
          />
        </section>
        <Footer />
      </Fragment>
    )
  }

  return project ? initPortfolioInfo() : <ErrorPage />;
}

export default PortfolioInfo;
