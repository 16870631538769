import React, { Fragment } from 'react';
import { motion, useIsPresent } from 'framer-motion';
import { TopNavbar, Header, About, Portfolio, Footer } from '../../containers';

const Home = () => {
  const isPresent = useIsPresent();

  return (
    <Fragment>
      <TopNavbar />
      <Header />
      <About />
      <Portfolio />
      <Footer />
      <motion.div
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circOut" } }}
        exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
        style={{ originX: isPresent ? 0 : 1 }}
        className="privacy-screen"
      />
    </Fragment>
  )
}

export default Home;
