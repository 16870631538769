import React, { Fragment } from 'react';
import FilterButton from '../filterbutton/FilterButton';

const Filter = ({ projects, setFiltered, activeProject, setActiveProject }) => {
  return (
    <Fragment>
      <FilterButton 
        filterId='all' 
        projects={projects}
        setFiltered={setFiltered}
        activeProject={activeProject}
        setActiveProject={setActiveProject} 
        content='All' 
      />
      <FilterButton 
        filterId='work-related' 
        projects={projects}
        setFiltered={setFiltered}
        activeProject={activeProject}
        setActiveProject={setActiveProject} 
        content='Work-related' 
      />
      <FilterButton 
        filterId='personal-projects' 
        projects={projects}
        setFiltered={setFiltered}
        activeProject={activeProject}
        setActiveProject={setActiveProject} 
        content='Personal projects' 
      />
      <FilterButton 
        filterId='email-templates' 
        projects={projects}
        setFiltered={setFiltered}
        activeProject={activeProject}
        setActiveProject={setActiveProject} 
        content='Email Templates' 
      />
    </Fragment>
  )
}

export default Filter;
