import React, { Fragment } from 'react';
import { useHashFragment } from '../../hooks/useHashFragment';

import styles from './links.module.css';

const CustomLink = ({ params }) => {
  const { toHref, isTarget, isRel, isClass, content } = params;

  useHashFragment();

  const attributes = {
    href: toHref,
    target: isTarget,
    rel: isRel,
    className: styles[isClass]
  }

  return (
    <Fragment>
      <a {...attributes}>
        {content}
      </a>
    </Fragment>
  )
}

export default CustomLink;
