import React, { useRef, useEffect } from 'react';
import TextShpere from '../../components/textshpere/TextShpere';
import { AboutLineOne, AboutLineTwo } from '../../components/svg/SvgIcons';
import { motion, AnimatePresence } from 'framer-motion';
import gsap from 'gsap-trial';
import ScrollTrigger from 'gsap-trial/ScrollTrigger';
import useViewport from '../../hooks/useViewport';

import styles from './about.module.css';

const About = () => {
  const { width } = useViewport();
  const sectionRef = useRef(null);

  if (width >= 768) {
    gsap.registerPlugin(ScrollTrigger)
  }

  useEffect(() => {
    const elem = sectionRef.current;
    if (width >= 768) {
      gsap.fromTo(elem, { opacity: 1 }, {
        opacity: 0,
        scrollTrigger: {
          trigger: elem,
          start: 'center',
          end: 'bottom',
          scrub: true
        }
      })
    }
  }, [width])

  return (
    <AnimatePresence>
      <motion.section
        ref={sectionRef}
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        id='about'
        className={styles['about']}
      >
        <AboutLineOne idTag='about-line-one' />
        <div className={styles['about_info']}>
          <h2 className={styles['about_info-title']}>
            A Unique Perspective on Life and Work
          </h2>
          <div className={styles['about_info-content']}>
            <p>
              As a front-end developer, I combine my love for coding with my passion
              for design to create beautiful and functional websites.
              My expertise includes HTML, CSS, JavaScript, CMS and React,
              and I am always eager to learn new technologies and frameworks.
            </p>
            <br />
            <p>
              When I'm not coding, you can find me staying active outdoors, whether it's hiking,
              biking, or playing sports. I believe that maintaining a healthy lifestyle is key to
              staying energized and productive.
            </p>
            <br />
            <p>
              I'm also an avid gamer and love exploring the world of video games.
              As a developer, I find the process of creating games fascinating,
              from designing the characters and environments to programming the game mechanics.
            </p>
            <br />
            <p>
              Overall, I am someone who is passionate about both programming and life,
              and I strive to bring that enthusiasm to everything I do.
            </p>
          </div>
        </div>
        <div className={styles['about-skills']}>
          <TextShpere />
        </div>
        <AboutLineTwo idTag='about-line-two' />
      </motion.section>
    </AnimatePresence>
  )
}

export default About;
