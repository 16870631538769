import React from 'react';
import { Link } from 'react-router-dom';

import styles from './logo.module.css';
import logo from '../../assets/logo.svg';

const Logo = () => {
  return (
    <Link to='/'>
      <img src={logo} className={styles['logo']} alt="Dev Raiks" title='Dev Raiks' />
    </Link>
  )
}

export default Logo
