import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { useLocation, useRoutes } from 'react-router-dom';
import { Home, PortfolioInfo, ErrorPage } from './pages';

import './App.css';

const App = () => {
  const element = useRoutes([
    {
      path: '/*',
      element: <ErrorPage />
    },
    {
      path: '/',
      element: <Home />,
      errorElement: <ErrorPage />
    },
    {
      path: '/project/:projectUrl',
      element: <PortfolioInfo />,
      errorElement: <ErrorPage />
    }
  ])

  const location = useLocation();

  if (!element) return null;

  return (
    <AnimatePresence mode="wait" initial={false}>
      {React.cloneElement(element, { key: location.pathname })}
    </AnimatePresence>
  )
}

export default App;
