import React from 'react';
import { motion } from 'framer-motion';
import { MenuItem } from './MenuItem';

const variants = {
  open: {
    left: '0px',
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  closed: {
    left: '-310px',
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

export const Navigation = ({ styles }) => (
  <motion.ul className={styles['mobile_links']} variants={variants}>
    {itemIds.map(i => (
      <MenuItem styles={styles} i={i} key={i} />
    ))}
  </motion.ul>
);

const itemIds = [0, 1, 2, 3];