import React from 'react';
import styles from './NavBar.module.css';

import { SvgLinkedin, SvgMail } from '../svg/SvgIcons.js';
import Logo from '../logo/Logo';
import CustomLink from '../customlinks/CustomLink.js';

const MainInfo = () => {
  return (
    <nav className={styles['nav']}>
      <ul>
        <li className={styles['nav_links-link']}>
          <CustomLink
            params={{
              toHref: '/#about',
              content: 'about'
            }}
          />
          <CustomLink
            params={{
              toHref: '/#portfolio',
              content: 'portfolio'
            }}
          />
        </li>
        <li>
          <Logo />
        </li>
        <li className={styles['nav_socials-social']}>
          <CustomLink 
            params={{
              toHref: 'https://www.linkedin.com/in/dmitriy-parhomenko-5101b9186/',
              isTarget: '_blank',
              isRel: 'noreferrer',
              isClass: 'icon-linkedin',
              content: <SvgLinkedin />
            }}
          />
          <CustomLink 
            params={{
              toHref: 'mailto:dmitriyparhomenko140@gmail.com',
              isRel: 'noreferrer',
              isClass: 'icon-mail',
              content: <SvgMail />
            }}
          />
        </li>
      </ul>
    </nav>
  )
}

export default MainInfo;
